import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import * as Icon from "./Icon";

const Button = props => <Ant.Button {...props} />;

const Switch = props => <Ant.Switch {...props} />;

const InputNumber = props => <Ant.InputNumber {...props} />;

const DetailedSwitch = ({ wrapperStyle = {}, ...props }) => (
  <div style={{ display: "flex", alignItems: "center", ...wrapperStyle }}>
    <div
      style={{
        visibility: props.checked ? "hidden" : "visible",
        fontSize: FontSize.caption,
        color: Color.GreyBlack_45,
        marginRight: 5,
      }}
    >
      關
    </div>
    <Ant.Switch {...props} />
    <div
      style={{
        visibility: props.checked ? "visible" : "hidden",
        fontSize: FontSize.caption,
        color: Color.Purple,
        marginLeft: 5,
      }}
    >
      開
    </div>
  </div>
);

const FlexCol = ({ style = {}, ...props }) => (
  <div
    style={{ display: "flex", flexDirection: "column", ...style }}
    {...props}
  >
    {props.children}
  </div>
);

const FlexRow = ({ style = {}, ...props }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      ...style,
    }}
    {...props}
  >
    {props.children}
  </div>
);

const FlexCenter = ({ style = {}, ...props }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      ...style,
    }}
    {...props}
  >
    {props.children}
  </div>
);

// font
export const FontSize = {
  largeTitle: 24,
  title: 20,
  subTitle: 18, // note: not in design guideline, but is used often.
  head: 16,
  body: 14,
  caption: 12,
};
const FontLargeTitle = styled.div`
  font-size: ${FontSize.largeTitle}px;
  color: rgba(0, 0, 0, 0.85);
  ${props => props.css}
`;

const FontTitle = styled.div`
  font-size: ${FontSize.title}px;
  color: rgba(0, 0, 0, 0.85);
  ${props => props.css}
`;

const FontSubTitle = styled.div`
  font-size: ${FontSize.subTitle}px;
  color: rgba(0, 0, 0, 0.85);
  ${props => props.css}
`;

const FontHead = styled.div`
  font-size: ${FontSize.head}px;
  color: rgba(0, 0, 0, 0.85);
  ${props => props.css}
`;

const FontBody = styled.div`
  font-size: ${FontSize.body}px;
  color: rgba(0, 0, 0, 0.85);
  ${props => props.css}
`;

const FontCaption = styled.div`
  font-size: ${FontSize.caption}px;
  color: rgba(0, 0, 0, 0.85);
  ${props => props.css}
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.25);
  ${props => props.css}
`;

export const Color = {
  Purple: "#534ab3",
  LightPurple: "#f4f6fc",
  LightBlue: "rgba(15, 14, 35, 0.25)",
  GreyBlack_04: "rgba(0, 0, 0, 0.04)",
  GreyBlack_10: "rgba(0, 0, 0, 0.1)",
  GreyBlack_15: "rgba(0, 0, 0, 0.15)",
  GreyBlack_25: "rgba(0, 0, 0, 0.25)",
  GreyBlack_45: "rgba(0, 0, 0, 0.45)",
  GreyBlack: "rgba(0, 0, 0, 0.65)",
  GreyBlack_65: "rgba(0, 0, 0, 0.65)",
  GreyBlack_85: "rgba(0, 0, 0, 0.85)",
  GreyGrey20: "#e0e0e0",
  GreyGrey50: "#8d8d8d",
  LightGreen: "#d9f7be",
  LightMagenta: "#ffd6e7",
  LightGold: "#faad14",
  WhiteGrey3: "d8d8d8",
  BlackBlack_1: "#404040",
  LightRed_5: "#ff4d4f",
  LightBlue_1: "#edecf7",
  CyanCyan_2: "#b5f5ec",
  LightPink: "rgba(255, 240, 229, 1)",
  // commit aa8491f
  GreyWhite_2: "#e5e5e5",
  GreyWhite_85: "rgba(255, 255, 255, 0.85)",
  Red_1: "#fff1f0",
  Red_5: "#ff4d4f",
  ChtBlue_2: "#6dbeff",
  ChtBlue_4: "#53b3ff",
  ChtBlue_5: "#209cff",
  ChtDBlue_5: "#0267b9",
  CHTMOrange_5: "#ff874d",
  CHTLOrange_5: "#fcaf15",
  CHTMGreen_5: "#47d382",
  CHTLGreen_5: "#96d34f",
  ChtBlue_6: "#0083ec",
  ChtLBlue_6: "#0691ff",
  ChtBlue_8: "#0075d3",
  ChtBlack_8: "#324250",
  //
  PurpleLight_2: "#bbbbcb",
  GreyBlack_12: "rgba(0, 0, 0, 0.12)",
  GreyWhite: "#ffffff",
  Red: "#F5222D",
  LightPurple_45: "#837ae8",
  // Local Styleguide
  MPurple_1: "#EDECF7",
  MPurple_1_50: "rgba(237,236,247,0.50)",
  MPurple_2: "#DCDAEF",
  MPurple_3: "#BAB6E0",
  MPurple_4: "#9792D1",
  MPurple_5: "#756EC2",
  MPurple_6: "#534AB3",
  MPurple_6_85: "rgba(83,74,179,0.85)",
  MPurple_6_30: "rgba(83,74,179,0.30)",
  MPurple_7: "#423A8F",
  MPurple_8: "#312C6B",
  MPurple_9: "#211D47",
  MPurple_10: "#0F0E23",
  MPurple_10_02: "rgba(15,14,35,0.02)",
  MPurple_10_06: "rgba(15,14,35,0.06)",
  MPurple_10_10: "rgba(15,14,35,0.10)",
  MPurple_10_20: "rgba(15,14,35,0.20)",
  MPurple_10_30: "rgba(15,14,35,0.30)",
  MPurple_10_40: "rgba(15,14,35,0.40)",
  MPurple_10_50: "rgba(15,14,35,0.50)",
  MPurple_10_60: "rgba(15, 14, 35, 0.6)",
  MPurple_10_60: "rgba(15,14,35,0.60)",
  MPurple_10_70: "rgba(15,14,35,0.70)",
  MPurple_10_80: "rgba(15,14,35,0.80)",
  MPurple_10_90: "rgba(15,14,35,0.90)",
  Black: "#000000",
  Black_85: "rgba(0,0,0,0.85)",
  Black_65: "rgba(0,0,0,0.65)",
  Black_45: "rgba(0,0,0,0.45)",
  Black_25: "rgba(0,0,0,0.25)",
  Black_15: "rgba(0,0,0,0.15)",
  Black_10: "rgba(0,0,0,0.10)",
  Black_06: "rgba(0,0,0,0.06)",
  Black_04: "rgba(0,0,0,0.04)",
  Black_02: "rgba(0,0,0,0.02)",
  Gray: "#161616",
  Gray_03: "#f5f5f5",
  Gray_90: "#2C2C2C",
  Gray_70: "#585858",
  Gray_50: "#BDBDBD",
  Gray_40: "#ABABAB",
  Gray_30: "#C6C6C6",
  Gray_20: "#E0E0E0",
  Gray_10: "#EFEFEF",
  Gray_05: "#F0F0F0",
  Gray_005: "#FCFCFC",
  GreenBlue_6: "#2F54EB",
  Green_6: "#52Ca1A",
  okGreen: "#6dd400",
  Gold_6: "#FAAD14",
  Red_6: "#FF4D4F",
  White: "#FFFFFF",
  Red: "#fa5050",
  Container_Light_CHT_01: "rgba(109, 190, 255, 0.2)",
  BBlack_1: "rgb(229, 231, 233, 0.3)",
  Selected_Blue: "#e4e0ef",
};

export const Font = {
  LargeTitle: FontLargeTitle,
  Title: FontTitle,
  SubTitle: FontSubTitle,
  Head: FontHead,
  Body: FontBody,
  Small: FontCaption,
};

export const Container = {
  Basic_Border: "solid 1px rgba(0, 0, 0, 0.15)",
  Basic_BGColor: "#FFFFFF",
  Disable_Border: "solid 1px rgba(0, 0, 0, 0.15)",
  Disable_BGColor: "rgba(0, 0, 0, 0.1)",
  Dashed_Border: "dashed 1px rgba(0, 0, 0, 0.15)",
  Dashed_BGColor: "#FFFFFF",
  Dashed_2_Border: "dashed 1px rgba(0, 0, 0, 0.25)",
  Dashed_2_BGColor: "rgba(0, 0, 0, 0.02)",
  Danger_Border: "solid 1px #ff4d4f",
  Danger_BGColor: "#FFFFFF",
  Danger_Border: "solid 1px #837ae8",
  Danger_BGColor: "#FFFFFF",
  Badge_Border: "solid 1px #ff4d4f",
  Badge_BGColor: "#ff4d4f",
  Error_Border: "solid 1px #ffa39e",
  Error_BGColor: "#fff1f0",
  Info_Border: "solid 1px #837ae8",
  Info_BGColor: "#f6f5ff",
  Info_2_Border: "solid 1px #91d5ff",
  Info_2_BGColor: "#e6f7ff",
  Success_Border: "solid 1px #b7eb8f",
  Success_BGColor: "#f6ffed",
  Warning_Border: "solid 1px #ffe58f",
  Warning_BGColor: "#fffbe6",
};

export const Shadow = {
  Basic:
    "0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12)",
  Hover:
    "0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16)",
  Modal:
    "0 12px 48px 16px rgba(0, 0, 0, 0.05), 0 9px 28px 0 rgba(0, 0, 0, 0.08), 0 6px 16px -5px rgba(0, 0, 0, 0.16)",
};

export function WarnLabel({ style = {}, children, ...props }) {
  return (
    <FlexRow style={style}>
      <Icon.InfoCircleOutlined
        color={Color.LightGold}
        style={{ width: 17, height: 17 }}
      />
      <Font.Small
        style={{
          marginLeft: 8,
          color: Color.LightGold,
          fontSize: `${FontSize.body}px`,
        }}
      >
        {children}
      </Font.Small>
    </FlexRow>
  );
}

export function WarnLabelBlock({ style = {}, children, ...props }) {
  return (
    <FlexRow
      style={{
        backgroundColor: "rgba(15, 14, 35, 0.7)",
        padding: "12px 46px 12px 32px",
        alignItems: "flex-start",
        ...style,
      }}
    >
      <Font.Body
        style={{
          color: "white",
          marginTop: 0,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Icon.InfoCircleOutlined
          color={"white"}
          style={{ marginRight: 12, marginTop: 4 }}
        />
        <p style={{ margin: "unset" }}>{children}</p>
      </Font.Body>
    </FlexRow>
  );
}

export function QuestionNumberBlock(props) {
  return (
    <div
      style={{
        width: 32,
        height: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: `${FontSize.body}px`,
        color: Color.GreyWhite,
        backgroundColor: Color.GreyBlack,
        flex: "0 0 auto",
        ...(props.extraStyle || {}),
      }}
    >
      {props.children}
    </div>
  );
}

export function CopyrightFooter(props) {
  return (
    <div
      style={{
        width: "100%",
        height: 60,
        marginTop: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          width: 538,
          margin: "auto",
          textAlign: "center",
          fontSize: `${FontSize.caption}px`,
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.67,
          letterSpacing: "normal",
          color: "#8d8d8d",
        }}
      >
        中華電信股份有限公司版權所有 Copyright © 2020 Chunghwa Telecom Co., Ltd.
        <br />
        數據發展處 客戶經營科 CRM 行銷平台 客服電話：(02)2326-6968 Email:
        crmop1@cht.com.tw
        <br />
        為提供穩定品質與最佳操作體驗，建議使用 Google Chrome 瀏覽器，系統 30
        分鐘無使用會自動登出
      </p>
    </div>
  );
}

/* css cheat sheet */
/* reset input css:
border: none; outline: none; box-shadow: none;
*/

export default {
  FlexCol,
  FlexRow,
  FlexCenter,
  Button,
  Switch,
  InputNumber,
  DetailedSwitch,
  WarnLabel,
  WarnLabelBlock,
  QuestionNumberBlock,
  CopyrightFooter,
  // font
  FontLargeTitle,
  FontTitle,
  FontSubTitle,
  FontHead,
  FontBody,
  FontCaption,
  Line,
};
